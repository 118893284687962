<template>
  <div class="content community">
    <banner title="About Us" :image="4"> </banner>

    <div class="section">
      <div class="content_wrapper">
        <h3>Making a difference in Colorado communities.</h3>
        <p>Organizations Supported by Taproot Energy Partners:</p>

        <div
          class="supported"
          v-for="(logo, index) of logos"
          :key="index"
          :style="{
            'background-image': `url(${require(`@/assets/images/supported/${logo}`)})`,
          }"
        ></div>
      </div>
    </div>

    <div class="section">
      <div class="content_wrapper">
        <h3>
          Taproot is committed to safe operations, dedication to local
          communities, and strict compliance with regulations.
        </h3>

        <ul>
          <li>0% Total Recordable Incident Rate, inception to date</li>
          <li>100% compliance with DOT, inception to date</li>
          <li>
            Uninterrupted compliance with all Colorado and federal regulations
          </li>
          <li>
            Permitting process requires strict analysis of environmental impact
            on local wetlands and ecosystems from the Bureau of Land Management
            and the United States Forest Service
          </li>
          <li>
            Respect and support ranchers near and around our midstream system
          </li>
          <li>
            Minimize Taproot's environmental and community impact through safe
            and efficient construction and ongoing operations
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "About-view",
  components: {
    Banner,
  },
  data() {
    return {
      logos: [
        "woga.png",
        "acs.png",
        "american-heart-association.png",
        "ArdentGroveFoundation.png",
        "briggsdale-school.png",
        "childrens-hospital-colorado.png",
        "RMPC.png",
        "wea.png",
        "Weld-Food-Bank.png",
        "ace-scholarships.png",
        "denver-petrolium-club.png",
        "co-oil-and-gas.png"
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.supported {
  margin: 20px;
  width: 250px;
  height: 120px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% auto;
  border: 1px solid var(--link_color_hover);
  transition: all 0.5s;
  &:hover {
    opacity: 0.95;
    background-size: 85% auto;
  }
}

@media all and (max-width: 650px) {
  .supported {
    display: block;
    margin: 40px auto;
    &.visible {
      opacity: 0.95;
      background-size: 85% auto;
    }
  }
}
</style>
